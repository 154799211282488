/**
 * Engineering Services ACF Layout
 */

.engineering {
	background: #131212;
	position: relative;

	&.layout {overflow: visible;} // Override default

	&__container {
		position: relative;
	}

	&__right {
		margin: 0 -1rem; // Parent padding override
		padding: 3rem 1rem;
		position: relative;

		&-background {
			filter: grayscale(100%);
			height: 100%;
			overflow: hidden;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			z-index: 1;

			&:after,
			&:before,
			&-gradient {
				@include size(100%);
				content: '';
				position: absolute;
					top: 0;
					left: 0;
				z-index: 2;
			}

			&:before {background: radial-gradient(circle at top, rgba(59,59,59,.75) 0%, #131212 90%);}
			&:after {background: linear-gradient(to bottom, transparent, #131212);}
			&-gradient {background: linear-gradient(to left, transparent 83%, #131212 100%);}

			img {@include object-fit(cover, center)}
		}

		&-content {
			position: relative;
			z-index: 3;
		}

		.link-list {
			margin: 2rem 0 0 0;

			li {border-bottom: 1px solid rgba($white, .3)}
		}
	}

	@include media($screen-sm) {
		margin-top: 14em;

		&__container {
			display: flex;
		}

		&__left {
			width: 23%;
			z-index: 2;

			&-bars {
				display: flex;
				height: 100%;
				margin-top: -8rem;
				overflow: hidden;
				padding-top: 8rem;
				position: absolute;
					top: 0;
					left: 0;
				width: 25%;
				z-index: 2;

				div {
					background: linear-gradient(180deg, #2D2D2D 0%, #131212 100%);
					border-right: 1px solid rgba($white, .3);
					display: inline-block;
					width: 50%;

					&:nth-child(1) {
						position: relative;

						&::before {
							background-color: white;
							content: '';
							height: 100%;
							position: absolute;
								bottom: 100%;
								left: 0;
							width: 101%;
						}

						&[data-scroll] {transform: translate3d(0, calc( var(--visible-y) * 20% ), 0);}
					}

					&:nth-child(2) {

						&[data-scroll] {transform: translate3d(0, calc( var(--visible-y) * -30% ), 0);}
					}
				}
			}

			&-image {
				position: absolute;
					top: 50%;
					left: -1rem;
				transform: translateY(-50%);
				width: 50%;
				z-index: 2;

				img {width: 100%;}
			}
		}

		&__right {
			margin: 0;
			padding: 4rem 2rem 4rem 25%;
			width: 77%;

			&:before { // "Methods" background text svg
				background: url('#{$libraryPath}/images/methods_flat_black.svg') no-repeat 0;
					background-size: contain;
				content: '';
				height: 50%;
				opacity: .04;
				position: absolute;
					top: -13rem;
					left: 0;
				width: 100%;
			}

			&-bars {
				display: flex;
				height: 100%;
				position: absolute;
					left: 0;
					top: 0;
				width: 100%;
				z-index: 2;
			}

			&-background:before {
				background: radial-gradient(circle at left, rgba(59,59,59,.7) 0%, #131212 70%);
			}
		}

		.link-list {
			margin: 2rem 0 0 4rem;

			li {max-width: 27.5rem;}
		}
	}

	@include media($screen-md) {
		&__right {
			&:before {top: -11rem;}
		}
	}
}


.precision-center {
	position: relative;
	z-index: 2;

	&:after {
		@include size(100%);
		background: linear-gradient(180deg, rgba(0, 0, 0, 0), #131212 60%);
		content: '';
		position: absolute;
			bottom: 0;
			left: 0;
	}

	hr {
		border-color: $red;
		border-width: 5px;
		width: 8.5rem;
	}

	.circle-link:after {
		background-color: transparent;
		border: 1px solid $white;
		left: 0;
	}

	&__bars {
		height: 100%;
		position: absolute;
			bottom: 50%;
			right: 0;
		width: 75%;

		&:after {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
			content: '';
			height: 50%;
			position: absolute;
				bottom: 0;
				left: 0;
			width: 100%;
		}
	}

	&__bar {
		background: $red;
		border-right: 1px solid #131212;
		height: 100%;
		transform-origin: bottom;
		width: 25%;

		&.bar-1 {
			transform: scaleY(.5);

			&[data-scroll] {transform: scaleY(calc( var(--viewport-y) * .4 + .5 ));}
		}

		&.bar-2 {
			transform: scaleY(.85);

			&[data-scroll] {transform: scaleY(calc( var(--viewport-y) * .7 + .85 ));}
		}

		&.bar-3 {
			transform: scaleY(.65);

			&[data-scroll] {transform: scaleY(calc( var(--viewport-y) * .5 + .65 ));}
		}

		&.bar-4 {
			transform: scaleY(1);

			&[data-scroll] {transform: scaleY(calc( var(--viewport-y) * .3 + .85 ));}
		}
	}

	&__left {
		position: relative;
		z-index: 2;
	}

	&__right {
		z-index: 2;

		img {
			position: relative;
			z-index: 2;
		}
	}

	@include media($screen-sm-max, 'max') {
		margin-bottom: 4rem;
		margin-top: calc(12rem + 8vw);

		&__right {
			img {margin-bottom: -10vw;}
		}
	}

	@include media($screen-sm) {
		&__left {
			padding: 20rem 3rem 6rem 0;
			padding-right: 3rem;
			width: 40%;
		}

		&__right {
			position: absolute;
				right: 0;
				bottom: -5rem;
			width: 65%;
		}
	}
}