.mega-dropdown {

	&.machines-menu {
		
	}

	@include media($mobileNavBP) {
		&.machines-menu {
			padding: 1.25rem;
			width: 64rem;

			.machines-menu__nav {
				margin-bottom: 1.5rem;

				> ul {
					display: flex;
						flex-wrap: wrap;
					margin-left: -1.25rem;
	
					li {
						padding-right: 1rem;
						width: 33%;
	
						a {
							padding: .75rem 1.25rem;
						}
					}
				}
			}

			.brands-menu__nav {

				> ul {
					display: flex;

					.main-menu__item {
						flex: 1;
						margin-right: 1rem;

						a {
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							display: block;
							height: 1.75rem;
							padding: 0;

							&:hover,
							&:focus {
								background-color: transparent; 
							}
						}

						&.methods a {background-image: url('#{$libraryPath}/images/logos/methods-isolated.png');}
						&.nakamura a {background-image: url('#{$libraryPath}/images/logos/nakamura-logo.png');}
						&.fanuc a {background-image: url('#{$libraryPath}/images/logos/fanuc-logo.png');}
						&.yasda a {background-image: url('#{$libraryPath}/images/logos/yasda-full-logo.png');}
						&.okk a {background-image: url('#{$libraryPath}/images/logos/okk-logo.png');}
						&.kiwa a {background-image: url('#{$libraryPath}/images/logos/kiwa-logo.png');}
						&.visiongauge a {background-image: url('#{$libraryPath}/images/logos/visiongauge-logo.png');}
						&.ocean a {background-image: url('#{$libraryPath}/images/logos/ocean-logo.png');}
						&.weiler a {background-image: url('#{$libraryPath}/images/logos/weiler-logo.png');}
					}
				}

				.nav-text {
					@include size(1px);
					border: 0;
					clip: rect(0 0 0 0);
					margin: -1px;
					overflow: hidden;
					padding: 0;
					position: absolute;
				}
			}
		}
	}

	@include media($screen-lg) {
		&.machines-menu {width: 76rem;}
	}

	@include media(85rem) {
		&.machines-menu {width: 82rem;}
	}
}

.mega-menu {

	@include media($mobileNavBPMax, 'max') {
		&__label {
			color: $white;
			display: block;
			padding: 1.25rem 1.25rem .25rem 1.25rem;
		}
	}

	@include media($mobileNavBP) {
		&__label {
			display: block;
			position: relative;

			&::after {
				border-top: 2px solid rgba(86, 86, 86, 0.22);
				content: '';
				position: absolute;
					top: 50%;
					left: 0;
				width: calc(100% - 20rem);
			}

			span {
				background: white;
				display: inline-block;
				padding-right: 1rem;
				position: relative;
				z-index: 2;
			}
		}

		// Machines Menu
		&__parent {
			> .menu__submenu {
				left: -1rem;
				width: auto;
			}
		}
	}

	@include media($screen-lg) {

		&__parent {
			> .menu__submenu {
				left: -1rem;
			}
		}
	}

	@include media(85rem) {
		&__parent {
			> .menu__submenu {
				left: -4.5rem;
			}
		}
	}
}


@include media($mobileNavBP) {
	// Resources Menu
	.menu-resources {

		> .menu__submenu {
			left: -25rem;
			width: auto;
		}
        
        .resources-menu__container {
			display: flex;
			width: 24rem !important;
        }
        
		.resources-menu__images {
			display: flex;
			width: 24rem;

			li {
				border: none;
				width: 50%;

				&:first-child {margin-right: 1rem;}

				.resources-menu__image {
					height: 0;
					padding-bottom: 100%;
					position: relative;
					overflow: hidden;

					&::after {
						@include size(100%);
						background: linear-gradient(to bottom, transparent 20%, black 100%);
						content: '';
						position: absolute;
							top: 0;
							left: 0;
					}

					img { @include object-fit(cover, center); }
				}

				a {
					color: white !important;
					padding: 0;
					position: absolute;
						bottom: 1rem;
						left: 1rem;
					width: calc(100% - 1.5rem);

					&:after {
						background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDFweCIgaGVpZ2h0PSI0MXB4IiB2aWV3Qm94PSIwIDAgNDEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODYwLjAwMDAwMCwgLTkyOS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjU5LjAwMDAwMCwgNzA2LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAyLjc5MTQ4OSwgMjI0LjcxODc1MCkiPgogICAgICAgICAgICAgICAgICAgIDxlbGxpcHNlIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyLjg2MDAwMDA2IiBjeD0iMTguNzM2MTcwMiIgY3k9IjE4LjcyNjU2MjUiIHJ4PSIxOC43MzYxNzAyIiByeT0iMTguNzI2NTYyNSI+PC9lbGxpcHNlPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS45OTg4NjI1LDE3Ljg3NzgwMDEgTDIyLjg2MTcxMjYsMTAuMjM0NjUyMiBDMjIuNDAyNjc2LDkuNzEyNzk1MDkgMjEuNDk0NDE2OSw5LjY2NjUxMTU5IDIwLjk2MDg4MTYsMTAuMTIwNDI5NiBDMjAuNDI4MjM4NSwxMC41NzQ3NzIzIDIwLjM3NDcwNjUsMTEuNDQwOTk1OCAyMC44Njg1Mzg5LDExLjkzMzEyOTYgTDI2LjAyNjM0NDIsMTcuNDUzMTgwOCBMOC40NzU4ODc5LDE3LjQ1MzE4MDggQzcuNzM2NzAwNjIsMTcuNDUzMTgwOCA3LjEzNzU4ODY1LDE4LjAyMzQ0NDYgNy4xMzc1ODg2NSwxOC43MjcwMzg4IEM3LjEzNzU4ODY1LDE5LjQzMDYzMyA3LjczNjcwMDYyLDIwLjAwMDg5NjggOC40NzU4ODc5LDIwLjAwMDg5NjggTDI2LjAyNjM0NDIsMjAuMDAwODk2OCBMMjAuODY4NTM4OSwyNS41MjA5NDggQzIwLjM3NDcwNjUsMjYuMDEzMDgxOCAyMC40NDExNzU0LDI2Ljg3NzYwNjggMjAuOTc0MjY0NiwyNy4zMzE5NDk0IEMyMS41MDczNTM4LDI3Ljc4NTg2NzUgMjIuNDAyNjc2LDI3Ljc0MTI4MjUgMjIuODYxNzEyNiwyNy4yMTk0MjUzIEwyOS45OTg4NjI1LDE5LjU3NjI3NzQgQzMwLjQ2NTAzNjgsMTguOTU4NDU2MyAzMC40MjgwMTA1LDE4LjQwMzQ3ODkgMjkuOTk4ODYyNSwxNy44Nzc4MDAxIiBmaWxsPSIjZmZmIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
						background-size: 1.75rem;
						top: auto;
						bottom: 0;
						transform: none;
					}

					&:hover,
					&:focus {background: transparent;}
				}
			}
		}

		.resources-menu {
			padding: 1rem 0;
			width: 45rem;

			> ul {
				display: flex;
					flex-flow: column wrap;
				max-height: 17rem;

				> li {
					width: 18rem;
				}
			}
		}
	}
}

@include media($screen-lg) {
	.menu-resources {

		> .menu__submenu {
			left: -15rem;
		}
	}
}