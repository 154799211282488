/**
 * Support Partial Layout
 */

.partial-support {
	content-visibility: auto;
}

.support {
	&__left {
		margin-bottom: 2rem;

		img {
			display: block;
			margin-bottom: 1rem;
			max-width: 20rem;
		}
	}

	&__right {
		h4 {margin-bottom: 0.5rem;}

		&-item {
			border-bottom: 1px solid #979797;
			display: flex;
				align-items: center;
			margin-bottom: 1rem;
			padding-bottom: 1rem;

			&:last-child {
				border: none;
				margin-bottom: 0;
			}
		}

		&-text.desktop-hidden {
			width: 100%;
		}

		.button--arrow {
			font-size: 1.2rem;

			&:after {
				background: url('#{$libraryPath}/icons/src/arrow-right-red.svg') no-repeat center !important;
					background-size: contain;
			}
		}

		.circle-link {
			background: $white; // Google was complaining about contrast on this element...
			font-size: 1.2rem;
			width: 100%;

			&:after {
				background-size: 1.75rem;
			}
		}
	}

	@include media($screen-sm) {
		display: flex;
			align-items: center;

		&__left {
			margin-bottom: 0;
			padding-right: 4rem;
			width: 60%;
		}

		&__right {
			width: 40%;

			&-icon {
				margin-right: 1rem;
				width: 3.5rem;
			}
		}
	}
}