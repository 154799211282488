/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * 	https://transfonter.org/
 *
 * 	Download the resulting files and copy the woff, and woff2
 * 	files into the appropriate directory.
 *
 * 	Make sure that the font-weight and font-style match the appropriate font.
 */

@font-face {
    font-family: 'Maven Pro';
    src: url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProRegular.woff2') format('woff2'),
         url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maven Pro';
    src: url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProBold.woff2') format('woff2'),
         url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maven Pro';
    src: url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProMedium.woff2') format('woff2'),
         url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maven Pro';
    src: url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProBlack.woff2') format('woff2'),
          url('https://dhros62t1qg9f.cloudfront.net/wp-content/themes/methods-machine/library/fonts/maven/subset-MavenProBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Teko';
    src: url('#{$libraryPath}/fonts/teko/subset-Teko-Bold.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/teko/subset-Teko-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Teko';
    src: url('#{$libraryPath}/fonts/teko/subset-Teko-Medium.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/teko/subset-Teko-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Teko';
    src: url('#{$libraryPath}/fonts/teko/subset-Teko-Light.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/teko/subset-Teko-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Teko';
    src: url('#{$libraryPath}/fonts/teko/subset-Teko-Regular.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/teko/subset-Teko-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Teko';
    src: url('#{$libraryPath}/fonts/teko/subset-Teko-SemiBold.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/teko/subset-Teko-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/**
 * Poppins
 */
 @font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Thin.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-ExtraLight.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Light.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Regular.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Medium.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-SemiBold.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Bold.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-ExtraBold.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('#{$libraryPath}/fonts/poppins/subset-Poppins-Black.woff2') format('woff2'),
         url('#{$libraryPath}/fonts/poppins/subset-Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}