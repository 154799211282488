.network-cta {

	&__container{
		
	}

	&__left {
		position: relative;

		&::after {
			content: '';
			background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
			height: 30%;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
		}

		img {
			display: block;

			// g > g:not(.network-map-paths) path {
			// 	stroke-dasharray: 500;
    		// 	stroke-dashoffset: 500;
			// }
		}

		&[data-scroll] {
			// g > g:not(.network-map-paths) path {
			// 	stroke-dasharray: calc( 300 + var(--viewport-y) * 300);
			// }
		}
	}

	&__right {
		.button--arrow {
			&::after,
			&::before {
				left: 0;
				right: auto;
			}

			&:after {
				left: .35rem;
			}			
		}
	}

	@include media($screen-sm-max, 'max') {
		position: relative;
		
		&__left {
			height: 100%;
			position: absolute;
				right: -3rem;
				bottom: 0;
			
			img {
				height: 100%;
				width: auto;
			}
		}

		&__right {
			padding: 4rem 0;
			position: relative;
			z-index: 2;
		}
	}

	@include media($screen-sm) {

		&__container {
			display: flex;
				align-items: center;
				justify-content: space-between;
		}
	
		&__left {
			width: calc(60% - 1rem);
		}
	
		&__right {
			padding: 6rem 0;
			width: calc(45% - 1rem);
		}
	}
}