/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 *		site-footer - the footer element
 *
 *			site-footer__navigation - footer menu
 *				footer-menu__item - footer menu item
 *
 *			site-footer__credits - copyright info container
 *
 *			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	background: linear-gradient(to top, #131212, #3B3B3B 170%);
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer
	z-index: 10; // Keep it above the map dots

	&__container {
		padding: 1.5em $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		cursor: pointer;
		text-decoration: none;
	}

	.site-footer__logo img {
		display: block;
		max-width: 18em;
		margin-bottom: 2em;
		width: 100%;
	}

	&__middle {
		border-top: 1px solid rgba($white, .21);
		padding: 1em 0;
	}

	&__bottom {
		border-top: 1px solid rgba($white, .21);
		padding: 1.5em 0;
		text-align: center;
	}

	&__navigation {
		ul {
			display: flex;
				flex-direction: column;
			text-decoration: none;
			text-transform: uppercase;
		}

		a {
			display: inline-block;
			padding: .5em 0;
		}
	}

	&__subscribe {
		@include media($screen-sm-max, 'max') {
			border-top: 1px solid rgba($white, .21);
			margin: 1.5rem 0;
			padding: 1.5rem 0 0;
		}
	}

	.social-media {

		&__link {display: inline-block;}

		a {
			display: block;
			padding: 0 .75em;
			width: 3.5em;
		}

		svg {
			display: block;

			path {fill: $white;}
		}
	}

	&__credits {
		display: flex;
			flex-direction: column;
		padding: 1em 0 0;
		text-align: center;
		width: 100%;

		&--legal {
			font-size: .9em;
			opacity: .75;
		}

		&--social {
			margin: 2rem 0;
		}

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				border-left: 1px solid $white;
				margin-left: .5rem;
				padding-left: .75rem;

				&:first-child {border: none;}
			}

			a {
				font-weight: normal;
				text-decoration: underline;
			}
		}
	}

	&__locations {
		a {
			font-weight: 600;
			text-transform: uppercase;

		}
	}

	&__bottom {
		display: flex;
			flex-wrap: wrap;
		text-align: center;

		& > a {
			display: block;
			margin: 1.5em auto;
		}

		strong {
			margin-bottom: 1rem;
			width: 100%;
		}
	}

	&__locations {
		display: flex;
			flex-wrap: wrap;
			justify-content: center;

		li {
			border-right: 1px solid rgba($white, .21);
			margin: 0 1rem 2rem 0;
			padding-right: 1rem;

			&:last-child {border: none;}
		}
	}


	@include media($screen-sm) {
		background: linear-gradient(to left, #131212, #3B3B3B 155%);

		&__top {
			display: flex;
				align-items: center;
				justify-content: space-between;
		}

		.site-footer__logo img {margin: 0;}

		&__middle {
			border-bottom: 1px solid rgba($white, .21);
			display: flex;
				justify-content: space-between;
			margin: 1.5em 0;
			padding: 0;

			&--left {
				border-right: 1px solid rgba($white, .21);
				padding: 1.5em 1.5em 1.5em 0;
			}

			&--right {
				flex: 1;
				padding: 1.5em 0 1.5em 1.5em;
			}
		}

		&__navigation {
			ul {
				flex-direction: row;
				flex-wrap: wrap;
				margin: -.5em 0 0 -1em;

				@include media($screen-lg) {
					li {width: 25%;}
				}
			}

			a {padding: .5em 1em;}
		}

		&__bottom {
			border: none;
			display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
			font-size: .85rem;
			padding: 0;
			text-align: left;

			& > a {
				display: inline-block;
				margin: 0;
			}

			strong {
				margin-right: 2rem;
				width: auto;
			}
		}

		&__locations {
			justify-content: flex-end;
			margin: 0 -.75rem 2rem 0 !important;
			width: calc(100% - 15.5em);

			a {
				display: block;
				margin-bottom: .5rem;
				padding: 0 .75rem;
			}

			li {
				border: none;
				margin: 0;
				padding: 0;
			}
		}

		&__credits {
			align-items: flex-end;
			flex-direction: row;
			justify-content: space-between;
			text-align: left;

			&--social {
				margin: 0;
				order: 2;

				.social-media a {width: 2.75em;}
			}
		}
	}
}
